<template>
  <div class="login-contrain">
    <div class="warp">
      <div class="login-from">
        <div class="login-title">
          <img src="" alt="">
          <span class="cn" style="font-weight: bold;font-size: 20px">迷夜Whatsapp客服控制台</span>
        </div>
        <el-form ref="form" :model="form" :rules="rules" label-width="auto">
          <el-form-item prop="username">
            <el-input v-model="form.username" prefix-icon='el-icon-user' placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" prefix-icon="el-icon-goods" type="password" placeholder="请输入登录密码" @keyup.enter.native="onSubmit"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: left">
              <el-checkbox v-model="checked">记住密码</el-checkbox>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%;" type="primary" @click="onSubmit" :loading="loading">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/request/api.js"
export default {
  components: {
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      rules: {
        username:[
            { required: true, message: '请输入用户名称', trigger: 'change' }
          ],
          password:[
            { required: true, message: '请输入登录密码', trigger: 'change' }
          ],
      },
      checked: false,
      loading: false
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      const result = await login(this.form)
      this.loading = false
      if (result && result.code === 200) {
        localStorage.setItem("token", result.data.chatToken)
        localStorage.setItem("chatWsAddr", result.data.chatWsAddr)
        localStorage.setItem("chatUid", result.data.chatUid)
        this.$message.success('登录成功')
        this.$router.push('/')
      }else{
        this.$message.error(result.message)
      }
    }
  }
}
</script>
<style lang="scss">
.login-contrain {
  // background-color: #fafafa;
  background-image: url('../../assets/login-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .warp {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    display: flex;
    justify-content: right;
  }

  .login-from {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 300px;
    padding: 40px;
    background-color: #fff;

    .login-title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .cn {
        display: 28px;
        font-weight: bold;
        color: #333;
      }

      .en {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}</style>
